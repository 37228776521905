import '../assets/uikit/css/uikit.min.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography, Container, Grid, Button, Avatar, Rating, Divider, Link } from '@mui/material';
import Chip from '@mui/material/Chip';
import { oPrimaryColor, primaryColor } from "../App";
import { secondaryColor } from "../App";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import HBackG from "../assets/images/image2.png"
import Tem from "../assets/images/tem.png"
import Tem2 from "../assets/images/tem2.png"
import Tem3 from "../assets/images/tem3.png"
import BackGCont from "../assets/images/cint.jpeg"
import Animation from "../assets/images/animation.mp4"
import Thumbnail from "../assets/images/thumbnail.png"
import Vector from "../assets/images/vector.png"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

import p1 from "../assets/images/partners/kiassure.png"
import p2 from "../assets/images/partners/neoliane.png"
import p3 from "../assets/images/partners/spvie.png"
import p4 from "../assets/images/partners/vital.png"
import p5 from "../assets/images/partners/zenico.png"
import p6 from "../assets/images/partners/joker.png"
import p7 from "../assets/images/partners/praeconis.png"

import CallIcon from '@mui/icons-material/Call';
import SendIcon from '@mui/icons-material/Send';

import FormContact from "../components/FormContact";

import * as React from 'react';
import { useRef } from 'react';

import FormDevis from '../components/FormDevis';
import { Helmet } from 'react-helmet-async';





export default function HomePage(props) {


    const [open3, setOpen3] = React.useState(false);
    const handleClickOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };


    const ref = useRef(null);


    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    
    const [open2, setOpen2] = React.useState(false);
    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };


    return (
        <>

        <Helmet>
            <title>Accueil - Occitane Assurances</title>
            <meta name="description" content="L'occitane est un courtier en assurances. Vous aurez la possibilité de choisir l'offre la plus avantageuse pour votre profil au meilleur tarif."></meta>
            <link rel='canonical' href='/'/>
        </Helmet>
        
        <Paper id="home" variant="outlined" sx={{ border: 0, width: '100vw', }}>
            
            <Box>
                <Card sx={{ boxShadow: 'none', borderRadius: 0, "--Card-radius": "0px" }}>

                    <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0 }} >
                        <img className="uk-animation-reverse uk-transform-origin-top-bottom" uk-scrollspy="cls: uk-animation-kenburns; repeat: true" src={HBackG} alt="Assurances" loading="lazy" />
                    </CardCover>
                    <CardCover sx={{ background: 'rgba(0,0,0,.4)', borderRadius: 0, }} />

                    <CardContent sx={{ m: 0, }}>
                        <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                            
                            <Box sx={{ minHeight: { xs: '40vh', sm: '60vh', md: '60vh', lg: '60vh' }, display: "flex", flexDirection: "column", justifyContent: "center", }}>
                                <Typography align="left" sx={{ typography: {xs: 'h5', sm: 'h3', md: 'h3'}, fontWeight: {xs: 'bold', sm: 'bold', md: 'bold'}, color: 'white', textTransform: 'uppercase' }} uk-scrollspy="cls: uk-animation-fade; delay: 500;">
                                    Courtier en Assurances & 
                                    <br />
                                    Mutuelle Santé
                                </Typography>
                                <br />
                                <Typography align="left" sx={{ typography: {xs: 'h6', sm: 'h6', md: 'h5'}, fontWeight: {xs: 'bold', sm: 'bold', md: 'bold'}, color: 'white', textTransform: 'uppercase' }} uk-scrollspy="cls: uk-animation-fade; delay: 500;">
                                    Particuliers & Professionnels
                                </Typography>
                                <hr style={{width: '15vw', height:'3px', backgroundColor: 'white'}} uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 700;"/>
                                <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                                    <Button onClick={handleClick} size="small" sx={{ backgroundColor: 'transparent', border: '2px solid', borderColor: 'white', fontWeight: 'bold', borderRadius: '0px', color: 'white', justifyContent: 'center', "&:hover": { color: primaryColor, backgroundColor: 'white' } }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 900;">Contactez Nous</Button>
                                </Box>

                            </Box>
 
                        </Container>
                    </CardContent>

                </Card>
            </Box>

            <Box sx={{ py: {xs:2, md:10, }, backgroundColor: '#fff' }}>

                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                    <Grid container spacing={4}>

                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: {xs: '90vw', md:1}, "--Card-radius": "0px" }}>
                                <CardCover>
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        poster={Thumbnail}
                                    >
                                        <source
                                            src={Animation}
                                            type="video/mp4"
                                        />
                                    </video>
                                </CardCover>
                            </Card>
                            
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Box>
                                <Typography variant="h6" align='right' fontWeight="bold" sx={{ color: secondaryColor, textTransform: 'uppercase', pb:2 }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 900;">
                                    Plus qu'une sensation d'être protégé !
                                </Typography>
                                <Box sx={{ typography: {xs: 'h5', sm: 'h3', md: 'h3'}, color: 'black', fontWeight: {xs: 'bold', sm: 'bold', md: 'bold'} }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300;">
                                    Offre d'assurances <br className='uk-visible@m'/>ou mutuelle <Typography sx={{ typography: {xs: 'h5', sm: 'h3', md: 'h3'}, display: 'inline', color: primaryColor, fontWeight: {xs: 'bold', sm: 'bold', md: 'bold'} }}>pour <br className='uk-visible@m'/>tout type de profil</Typography>
                                </Box>
                                <hr style={{width: '7vw', height:'5px', backgroundColor: primaryColor}} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 400;"/>
                                <Typography variant='h6' fontWeight="bold" sx={{ pb:2 }} uk-scrollspy="cls: uk-animation-fade; delay: 500;">
                                    Faites des économies sur tous vos contrats :
                                </Typography>

                                <Grid spacing={2} container uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300; target: > div;">

                                    <Grid xs={6} item >
                                        <Card sx={{ backgroundColor: '#f5f5ef', color: 'black', textDecoration: 'none', '&:hover': {color: primaryColor} }} component={Link} onClick={handleClickOpen2}>
                                            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center' }}>
                                                    <HealthAndSafetyIcon align="center" sx={{ fontSize:'3rem', mb:1 }}/>
                                                    <Typography fontWeight="bold">Mutuelle Santé</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <FormDevis open2={open2} handleClose2={handleClose2} demande="Mutuelle Santé"/>
                                    </Grid>

                                    <Grid  xs={6} item>
                                        <Card sx={{ backgroundColor: '#f5f5ef', color: 'gray', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }} component={Link}>
                                            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center' }}>
                                                    <DirectionsCarIcon align="center" sx={{ fontSize:'3rem', mb:1 }}/>
                                                    <Typography fontWeight="bold" >Assurance Auto</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid  xs={6} item>
                                        <Card sx={{ backgroundColor: '#f5f5ef', color: 'gray', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }} component={Link}>
                                            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center' }}>
                                                    <TwoWheelerIcon align="center" sx={{ fontSize:'3rem', mb:1 }}/>
                                                    <Typography fontWeight="bold">Assurance Moto</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid  xs={6} item>
                                        <Card sx={{ backgroundColor: '#f5f5ef', color: 'gray', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }} component={Link}>
                                            <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "center", }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center' }}>
                                                    <HomeWorkIcon align="center" sx={{ fontSize:'3rem', mb:1 }}/>
                                                    <Typography fontWeight="bold" align='center'>Assurance Habitation</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                </Grid>

                            </Box>

                        </Grid>

                    </Grid>

                </Container>
            </Box>
            
            <Box className='uk-visible@m' sx={{ position: 'absolute', background: oPrimaryColor, height: '7vh', width: '20vw', left: 0, transform: 'translateY(-50%)' }}></Box>
            
            <Box  sx={{ background:'linear-gradient(to right, #fff, #f5f5ef, #fff, #f5f5ef, #fff)', pt: 10, pb: 5 }}>


                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                    <Grid container spacing={4}>

                        <Grid xs={12} md={6} item>

                            <Typography variant="h5" fontWeight="bold" sx={{ color: primaryColor, pb:2 }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300; ">
                                L'Occitane - Assurance
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Vous ne savez pas quelle assurance choisir pour votre maison, votre crédit ou votre voiture ? Quelle mutuelle santé répond le plus à vos besoins ? A votre activité professionnelle atypique ?
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Notre métier de courtage en assurance est avant tout d’être à votre écoute et de vous guider.
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Nous recherchons et comparons pour vous les meilleurs contrats, sans que vous ayez à en assumer le stress. Ce qui n’est pas négligeable si votre dossier est complexe ou que vous faites partie des profils dits à risque aggravé…
                            </Typography>

                            <Typography variant="h5" fontWeight="bold" sx={{ color: primaryColor, pb:2, pt:7 }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300; ">                             
                                Quel intérêt de passer par un courtier en assurance ?
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Le courtier en assurance a l'avantage de travailler avec plusieurs compagnies. Il aura donc une vision globale de toutes les offres possibles pour assurer une entreprise. Grâce à son expertise, il sera donc en mesure de sélectionner les contrats les plus adaptés pour répondre aux besoins d'assurance.
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Le courtier en assurance vous permet un gain de temps et d'argent non négligeable. Vous n'avez plus à chercher et comparer les offres de tous les assureurs, le courtier le fait pour vous.
                            </Typography>

                            <Typography variant="h5" fontWeight="bold" sx={{ color: primaryColor, pb:2, pt:7 }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300; ">
                                Complémentaire santé : quels sont les avantages d'une mutuelle santé ?
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'black', pb:1 }} uk-scrollspy="cls: uk-animation-fade; delay: 500; ">
                                Les soins pour préserver votre santé peuvent s’avérer coûteux au fil du temps. Or, les remboursements des soins par l’Assurance maladie n’en couvrent qu’une partie. Pour réduire votre facture et vous assurer une protection optimale, la complémentaire santé, ou mutuelle santé, est votre meilleure alliée.
                            </Typography>

                        </Grid>

                        <Grid xs={12} md={1} className='uk-visible@m' item>
                            <img src={Vector} alt="Vector" uk-scrollspy="cls: uk-animation-fade; delay: 900" loading="lazy"/>
                        </Grid>

                        <Grid xs={12} md={5} item uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300; target: > div;">

                            <Typography variant='h5' fontWeight="bold" sx={{ color: 'black', pb:3, }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 300">Ce que disent nos clients</Typography>

                            <Card sx={{ backgroundColor: '#fff', color: 'black', mb:5,  }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:'center' }}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={Tem}
                                            sx={{ width: 56, height: 56 }}
                                            loading="lazy"
                                        />
                                        <Rating name="read-only" value={5} size='small' sx={{ color: primaryColor, ml:2 }} readOnly />
                                    </Box>
                                    <Typography variant="body1" sx={{ color: 'black', pb:1, pt:2 }} >
                                        &ldquo; Très satisfaisant, bien détaillé et rapidement contacté par des conseillers. Cordialement. &rdquo;
                                    </Typography>
                                    <Typography fontSize=".7rem" sx={{ color: 'black', pb:1 }} >
                                        Témoignage recueilli le 24/09/2022 , pour une comparaison effectuée le 21/09/2022 .
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card sx={{ backgroundColor: '#fff', color: 'black', mb:5, }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:'center' }}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={Tem3}
                                            sx={{ width: 56, height: 56 }}
                                            loading="lazy"
                                        />
                                        <Rating name="read-only" value={5} size='small' sx={{ color: primaryColor, ml:2 }} readOnly />
                                    </Box>
                                    <Typography variant="body1" sx={{ color: 'black', pb:1, pt:2 }} >
                                        &ldquo; Très bien Très simple et résultats satisfaisants. &rdquo;
                                    </Typography>
                                    <Typography fontSize=".7rem" sx={{ color: 'black', pb:1 }} >
                                        Témoignage recueilli le 27/09/2022 , pour une comparaison effectuée le 23/09/2022 .
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card sx={{ backgroundColor: '#fff', color: 'black', mb:5, }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems:'center' }}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={Tem2}
                                            sx={{ width: 56, height: 56 }}
                                            loading="lazy"
                                        />
                                        <Rating name="read-only" value={5} size='small' sx={{ color: primaryColor, ml:2 }} readOnly />
                                    </Box>
                                    <Typography variant="body1" sx={{ color: 'black', pb:1, pt:2 }} >
                                        &ldquo; Excellent, il permet vraiment de dégoter les meilleurs tarifs d'assurance.&rdquo;
                                    </Typography>
                                    <Typography fontSize=".7rem" sx={{ color: 'black', pb:1 }} >
                                        Témoignage recueilli le 27/09/2022 , pour une comparaison effectuée le 23/09/2022 .
                                    </Typography>
                                </CardContent>
                            </Card>
                            
                            
                        </Grid>

                    </Grid>


                    

                </Container>


            </Box>

            <Box  sx={{ py: 10, position: 'relative' }}>

                <Box sx={{ position: 'absolute', background: 'linear-gradient(to right, #f5f5ef 52%, #fff, #fff)', width: '30vw', height:1, bottom: 0, left: 0 }}/>

                <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                    <Grid container spacing={2} uk-scrollspy="cls: uk-animation-slide-left-small; delay: 300; target: > div;">

                        <Grid item xs={6} sm={4} md={2} sx={{ position: 'relative',}}>

                            <Box sx={{ minHeight: '70px', }}>
                                <Typography variant="body2" fontWeight="bold" sx={{ color: secondaryColor, position: 'relative', }} >
                                    Nos différents
                                </Typography>
                                <Typography variant="h5" fontWeight="bold" sx={{ color: primaryColor, position: 'relative', ml: 3 }} >
                                    Partenaires
                                </Typography>
                            </Box>
                            
                        </Grid>

                        <Grid item xs={6} sm={4} md={2} >
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p1} alt="Kiassure" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} >
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p2} alt="Néoliane" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                        
                        
                        <Grid item xs={6} sm={4} md={2} >
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p5} alt="Zénico" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy" />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} >
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p4} alt="Vitalité" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}  ref={ref}>
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p3} alt="SPVIE" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}  ref={ref}>
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p6} alt="SPVIE" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}  ref={ref}>
                            <Box sx={{ minHeight: '70px', px:5, display: "flex", flexDirection: "column", justifyContent: "center", height: 1 }}>
                                <Box component="img" src={p7} alt="SPVIE" sx={{ filter: 'grayscale(1)', transition: "all 0.5s", '&:hover': {filter: 'grayscale(0)',} }} loading="lazy"/>
                            </Box>
                        </Grid>
                                
                    </Grid>
                </Container>
            </Box>

            <Box>
                <Card sx={{ boxShadow: 'none', borderRadius: 0, py: 10, "--Card-radius": "0px" }}>

                    <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0 }} >
                        <img className="uk-animation-reverse uk-transform-origin-top-right" uk-scrollspy="cls: uk-animation-kenburns;" src={BackGCont} alt="Contact Occitane Assurances" loading="lazy"/>
                    </CardCover>

                    <CardCover sx={{ background: 'linear-gradient(to bottom, rgba(255,255,255,1) 1%, rgba(0,0,0,0) )',  borderRadius: 0, }} />

                    <CardContent sx={{ m: 0, }}>
                        <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <Paper sx={{ background:'linear-gradient(to bottom right, #fff, lightgray 70%, #fff)', p: {xs:3, md:4} }} square elevation={6} uk-scrollspy="cls: uk-animation-scale-up; delay: 900">

                                        <Chip label="Contact" size="medium" align="right" sx={{ backgroundColor: secondaryColor, color: 'white', fontWeight: 'bold', mb:2, textTransform: 'uppercase' }} uk-scrollspy="cls: uk-animation-fade; delay: 1100"/>
                                        <Typography variant="h4" align="left" sx={{ typography: {xs: 'h5', md: 'h4'}, fontWeight: {xs: 'bold', md: 'bold'}, color: primaryColor, p:0, m:0 }}>
                                            Joindre un conseiller
                                        </Typography>

                                        <hr style={{width: '10vw', height:'3px', backgroundColor: 'lightgray'}}/>

                                        <Box  sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', py:2 }}>
                                            <SendIcon sx={{ color: primaryColor }} fontSize="large" />
                                            <Box>
                                                <Typography sx={{ color:'black', fontWeight:'bold', ml:1 }}>Par Email</Typography>
                                                <Box sx={{ color:'gray',  ml:1 }}>
                                                    avec notre <Link onClick={handleClickOpen3} sx={{ textDecoration: 'none', display: 'inline', fontWeight: 'bold' }}><Typography sx={{ display: 'inline', fontWeight: 'bold', color: primaryColor }}>formulaire de contact</Typography></Link>
                                                </Box>
                                            </Box>

                                            <FormContact open3={open3} handleClose3={handleClose3}/>

                                        </Box>

                                        <Divider variant="middle" />

                                        <Box  sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', py:2 }}>
                                            <CallIcon sx={{ color: primaryColor }} fontSize="large" />
                                            <Box>
                                                <Typography sx={{ color:'black', fontWeight:'bold', ml:1 }}>Par Téléphone</Typography>
                                                <Box sx={{ color:'gray',  ml:1, }}>         
                                                    Composer le <Link href='tel:+33145179849' sx={{ textDecoration: 'none', display: 'inline-block', fontWeight: 'bold' }}><Typography sx={{ display: 'inline-block', fontWeight: 'bold', color: primaryColor }}>0145179849</Typography></Link>
                                                </Box>
                                            </Box>
                                        </Box> 
  
                                    </Paper>
                                </Grid>
                            </Grid>
 
                        </Container>
                    </CardContent>

                </Card>
            </Box>
                
        </Paper>
        </>
    );
}