import { Box } from '@mui/material';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import DescriptionIcon from '@mui/icons-material/Description';
import { primaryColor } from '../App';
import * as React from 'react';
import FormCallback from "./FormCallback";
import FormDevis from "./FormDevis";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';


function FloatingButton() {


    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };



    

    
    

    return (
        <Box sx={{ position: 'fixed', zIndex: 1000, right: 0, bottom: 0, display: {xs:'flex', md:"none"}, flexDirection: "column", justifyContent: "center", alignItems:'center',  }}>
               
               <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, right: 16, }}
                    icon={<SpeedDialIcon />}
                    FabProps={{
                        sx: {
                          background: primaryColor,
                        
                        }
                      }}
                >
                    <SpeedDialAction
                        icon={<PhoneCallbackIcon />}
                        tooltipTitle="Être rappelé"
                        onClick={handleClickOpen1}
                    />
                    <SpeedDialAction
                        icon={<DescriptionIcon />}
                        tooltipTitle="Devis"
                        onClick={handleClickOpen2}
                    />
                </SpeedDial>
           
            
            <FormCallback open1={open1} handleClose1={handleClose1}/>
            <FormDevis open2={open2} handleClose2={handleClose2}/>
            
        </Box>
    );
}

export default FloatingButton;
