import { Box, Container, Typography, Link } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { secondaryColor } from "../App";

const UpperBar = () => {
    

    return (
        <Stack position="relative" sx={{ width: '100%', background: 'white',  display: { xs: 'flex', sm: 'flex' } }}>
            <Container maxWidth="xl">

                <Box sx={{ display: 'flex', mt:0.3, mb:0.3 }} justifyContent="space-between" alignItems="center">

                    <Box sx={{ display: 'flex', color: 'black'}}>

                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }} gap={.3}>
                        <Typography variant='body2' color="gray" fontSize=".7rem" className='uk-visible@s' >Courtage et assurance en ligne pour tout profil</Typography>
                        <Link href='tel:+33145179849' sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', }}>
                            <CallIcon sx={{ color: secondaryColor }} />
                            <Typography sx={{ background: secondaryColor, px:2, py:.5, color:'white', fontWeight:'bold', ml:1 }}>0145179849</Typography>
                        </Link>
                        <IconButton component={Link} href="https://wa.me/+33145179849" target="_blank" sx={{ borderRadius: 0, py:.5, backgroundColor: 'lightgray', transition: "all 0.5s", '&:hover':{ backgroundColor: secondaryColor, }, '& > :first-of-type': {color: secondaryColor, transition: 'all 0.5s'}, '&:hover > :first-of-type': {color: 'white'} }}>
                            <WhatsAppIcon />
                        </IconButton>
                    </Box>

                </Box>
                
            </Container>
        </Stack>
    );
};

export default UpperBar;
