import React from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Grid, MenuItem, FormControl, FormLabel, FormControlLabel, RadioGroup } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { oSecondaryColor, secondaryColor } from '../App';
import Api from './Api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CssTextField2 = styled(TextField)({
    '& label': {
        borderBottomColor: 'red',
        fontWeight: 'bold',
    },
    '& label.Mui-focused': {
        color: '#7ba736',
        fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
        borderColor: '#7ba736',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 0,
            borderColor: '#7ba736',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#7ba736',
        },
    },

});




class FormDevis extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            nom: '',
            prenom: '',
            conjoint: '',
            tel: '',
            email: '',
            regime: '',
            demande: this.props.demande || '',
            alert: false,
            success: false
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }


    handleClose2() {
        this.setState({ open2: true });
    }

    // Input Change Handler
    changeHandler(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    async postData() {
        let devis = this.state;
        await Api.post("/devis/", devis)
            .then(response => {
                if (response.status === 201) {
                    this.setState({ alert: true, success: true });
                    this.setState({
                        nom: '',
                        prenom: '',
                        conjoint: '',
                        tel: '',
                        email: '',
                        regime: '',
                        demande: '',
                    });
                }
            });
    }


    submitForm(e) {
        e.preventDefault();
        this.postData();
    }

    closeAlert() {
        this.setState({
            alert: false
        });
    }

    render() {
        return (

            <Dialog maxWidth="md" TransitionComponent={Transition} open={this.props.open2} onClose={this.props.handleClose2} sx={{ borderRadius: 0, }}>

                <Grid container>
                    <Grid item xs={12} md={4} sx={{ background: 'linear-gradient(300deg, #7ba736, #3981bf)', }}>
                        <DialogTitle sx={{ color: 'white', typography: 'h5', fontWeight: 'bold', textTransform: 'uppercase', mt: 2 }}>Demande de devis</DialogTitle>
                        <DescriptionIcon sx={{ width: 1, fontSize: { xs: '120px', sm: '140px', md: '160px', lg: '180px' }, color: 'rgba(0,0,0,.6)' }} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ background: 'linear-gradient(340deg, gray, white, white, white, white)', }}>
                            <DialogContent>
                                <form onSubmit={this.submitForm} method="post">
                                    <DialogContentText sx={{ py: 2, color: 'black', fontWeight: 'bold' }}>
                                        Remplissez le formumaire suivant et votre devis vous sera envoyé dans les plus brefs délais.
                                    </DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Nom" variant="outlined" required name="nom" value={this.state.nom} onChange={this.changeHandler} />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Prénom" variant="outlined" required name="prenom" value={this.state.prenom} onChange={this.changeHandler} />
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={4}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" sx={{ '&.Mui-focused': { color: secondaryColor, fontWeight: 'bold' }, fontWeight: 'bold' }}>Conjoint(e) ?</FormLabel>
                                                <RadioGroup aria-label="position" row>
                                                    <FormControlLabel
                                                        value="Oui"
                                                        control={<Radio size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} name="conjoint" value="Oui" onChange={this.changeHandler} checked={this.state.conjoint === "Oui"} />}
                                                        label="Oui"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Non"
                                                        control={<Radio size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} name="conjoint" value="Non" onChange={this.changeHandler} checked={this.state.conjoint === "Non"} />}
                                                        label="Non"
                                                        labelPlacement="end"
                                                    />

                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={8}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Email" variant="outlined" required type="email" name="email" value={this.state.email} onChange={this.changeHandler} />
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Téléphone" variant="outlined" required type="tel" name="tel" value={this.state.tel} onChange={this.changeHandler} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Régime" variant="outlined" required select name="regime" value={this.state.regime} onChange={this.changeHandler}>
                                                <MenuItem value="Agricole">Agricole</MenuItem>
                                                <MenuItem value="Alsace Moselle">Alsace Moselle</MenuItem>
                                                <MenuItem value="SS - Salarié">SS - Salarié</MenuItem>
                                                <MenuItem value="SS - TNS indép. / retraité / agri.">SS - TNS indép. / retraité / agri.</MenuItem>
                                            </CssTextField2>
                                        </Grid>



                                        <Grid item xs={12} sm={6} md={6}>
                                            <CssTextField2 fullWidth id="standard-basic" label="Votre demande concerne ?" required variant="outlined" select name="demande" value={this.state.demande} onChange={this.changeHandler}>
                                                <MenuItem value="Mutuelle Santé">Mutuelle Santé</MenuItem>
                                            </CssTextField2>
                                        </Grid>

                                    </Grid>
                                    <DialogActions sx={{ pt: 5 }}>
                                        <Button onClick={this.props.handleClose2} sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: 'transparent', fontWeight: 'bold', borderRadius: '0px', color: 'gray', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: 'transparent' } }}>Annuler</Button>
                                        <Button type="submit" sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: secondaryColor, fontWeight: 'bold', borderRadius: '0px', color: 'white', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: oSecondaryColor } }}>Envoyer<SendIcon fontSize="small" sx={{ ml: 1 }} /></Button>
                                    </DialogActions>
                                </form>
                            </DialogContent>


                        </Box>
                    </Grid>
                </Grid>

                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} onClose={this.closeAlert}>
                        
                        { this.state.success ? 
                            <Alert severity="success" sx={{ width: '100%' }}>
                                Votre demande a été prise en compte!
                            </Alert> 
                            : 
                            <Alert severity="error" sx={{ width: '100%' }}>
                                La demande n'a pas été prise en compte.
                            </Alert> 
                        }
                        
                </Snackbar>

            </Dialog>


        );
    }
}

export default FormDevis;