import '../assets/uikit/css/uikit.min.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function ErrorPage() {

    

    return (
        <>
            <Helmet>
                <title>Erreur - Occitane Assurances</title>
                <meta name="description" content="L'occitane est un courtier en assurances. Vous aurez la possibilité de choisir l'offre la plus avantageuse pour votre profil au meilleur tarif."></meta>
                <link rel='canonical' href='/404'/>
            </Helmet>
            
            <Paper id="error" variant="outlined" sx={{ border: 0, width: '100vw', height: '90vh' }}>
            
                <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{ typography: {xs: 'h3', md: 'h1'}, fontWeight: {xs: 'bold', md: 'bold'}, fontFamily: {xs: 'Roboto', md: 'Roboto'} }}>404</Typography>
                    <Typography sx={{ typography: {xs: 'h3', md: 'h1'}, fontWeight: {xs: 'bold', md: 'bold'}, fontFamily: {xs: 'Roboto', md: 'Roboto'} }}>Page inexistante</Typography>
                </Box>
                    
            </Paper>
        </>
        
    );
}