import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import HomePage from './pages/HomePage';
import HealthPage from './pages/HealthPage';
import ErrorPage from './pages/ErrorPage';
import { HelmetProvider } from 'react-helmet-async';


const router = createBrowserRouter([

    {
        element: <App />,

        children: [

            {
                path: "/",
                element: <HomePage title="Accueil - L'Occitane" />,
            },

            {
                path: "/mutuelle-sante",
                element: <HealthPage title="Mutuelle Santé - L'Occitane" />,
            },

            {
                path: "/404",
                element: <ErrorPage title="L'Occitane" />,
            },

            {
                path: "*",
                element: <Navigate to="/404" />,
            },
            

          ],
    },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
    </React.StrictMode>
);


