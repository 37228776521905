import { Box, Container, Typography, Link, Grid } from '@mui/material';
import React from 'react';
import logoF from "../assets/images/logo/logo2.png";

import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { primaryColor } from "../App";

import { Link as lnk }  from "react-router-dom";
import FormContact from './FormContact';

const Footer = () => {
    
    const [open3, setOpen3] = React.useState(false);
    const handleClickOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };

    return (
        <Box position="relative" sx={{ width: '100%', pt: 7, pb: {xs: 0, md:7}, background:'linear-gradient(120deg, lightgray, #fff, lightgray, #fff)' }}>
            <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={7} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pb:3 }}>
                            <Box component="img" src={logoF} align='center' sx={{ maxHeight: '70px', }} alt="L'Occitane Assurances" loading="lazy"/>
                            <Typography variant='body2' align='center' fontWeight='bold' sx={{ color:'gray', mt:2 }}>Comparateur en ligne d’assurances auto, santé, habitation et moto, spécialisés en solutions pour profil à risque aggravé, nous accompagnons particuliers et professionnels partout en France.</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:1 }}>
                            <Typography variant='h5' sx={{ color: primaryColor, fontWeight: 'bold', mb:2 }}>Navigation</Typography>
                            <Link component={lnk} to="/mutuelle-sante" sx={{ color: 'gray', fontWeight: 'bold', textDecoration: 'none', '&:hover': {color: primaryColor} }}>Mutuelle Santé</Link>
                            <Link sx={{ color: 'gray', fontWeight: 'bold', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }}>Assurance Auto</Link>
                            <Link sx={{ color: 'gray', fontWeight: 'bold', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }}>Assurance Moto</Link>
                            <Link sx={{ color: 'gray', fontWeight: 'bold', textDecoration: 'none', '&:hover': {color: primaryColor}, pointerEvents:'none' }}>Assurance Habitation</Link>
                            <Link onClick={handleClickOpen3} sx={{ color: 'gray', fontWeight: 'bold', textDecoration: 'none', '&:hover': {color: primaryColor} }}>Contact</Link>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:1 }}>
                            <Typography variant='body2' sx={{ color: primaryColor, fontWeight: 'bold', mb:2 }}>Copyright L'Occitane. Tous droits réservés.</Typography>
                            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                                <IconButton sx={{ color: primaryColor,  }}>
                                    <FacebookIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor,  }}>
                                    <TwitterIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor,  }}>
                                    <InstagramIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor,  }}>
                                    <LinkedInIcon fontSize='small' />
                                </IconButton>
                                
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
                
            </Container>

            <FormContact open3={open3} handleClose3={handleClose3}/>
        </Box>
    );
};

export default Footer;