import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import UpperBar from './UpperBar'

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import logoF from "../assets/images/logo/logo.png";
import { Link } from "react-router-dom";


import { primaryColor, } from "../App";

import FormContact from './FormContact';

import { Drawer } from '@mui/material';


const drawerWidth = 240;

function ResponsiveAppBar(props) {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [anchorAss, setAnchorAss] = React.useState(null);


    const handleOpenAss = (event) => {
        setAnchorAss(event.currentTarget);
    };

    const handleCloseAss = () => {
        setAnchorAss(null);
    };

    const pages = [
        { id: 1, title: "Assurances", to: "/assurances" },
        { id: 2, title: "Mutuelle Santé", to: "/mutuelle-sante" },
        { id: 3, title: "Contact", to: "/contact" }
    ]

    const assurances = ['Assurance Auto', 'Assurance Moto', 'Assurance Habitation'];

    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };




    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <AppBar position="sticky" sx={{ background: 'linear-gradient(to right, #fff, lightgray, #fff, lightgray)', width: '100vw', }}>

            <UpperBar />

            <Container maxWidth="xl">

                <Toolbar disableGutters sx={{ justifyContent: "space-between" }} >

                    <Link to="/">
                        <Box component="img" src={logoF} sx={{ maxHeight: '60px', }} alt="L'Occitane Assurances" loading="lazy" />
                    </Link>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon sx={{ color: primaryColor }} />
                        </IconButton>
                        <Drawer container={container} anchor="left" variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'linear-gradient(120deg, #fff, lightgray 20%, #fff, lightgray)' } }}>
                            <Box component="img" loading="lazy" src={logoF} sx={{ width: 1, px: 4, py: 2 }} alt="L'Occitane Assurances" />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 1 }} onClick={handleDrawerToggle}>
                                <Button component={Link} to="/" sx={{ py: 2, color: 'oPrimaryColor', borderRadius: '0px', width: 1, fontWeight: 'bold', '&:hover': { color: primaryColor } }}>Accueil</Button>
                                {pages.map((page) => (
                                    <Button component={Link} to={page.title === 'Mutuelle Santé' ? page.to : undefined}
                                        key={page.id}
                                        disabled={page.title === 'Assurances' ? true : false}
                                        endIcon={page.title === 'Assurances' ? <KeyboardArrowDownIcon /> : ''}
                                        onClick={page.title === 'Assurances' ? handleOpenAss : handleDrawerToggle && page.title === 'Contact' ? handleClickOpen3 : undefined}
                                        sx={{ py: 2, color: 'oPrimaryColor', borderRadius: '0px', width: 1, fontWeight: 'bold', '&:hover': { color: primaryColor } }}
                                    >
                                        {page.title}
                                    </Button>
                                ))}
                            </Box>
                            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', py: 2 }}>
                                <IconButton sx={{ color: primaryColor, }}>
                                    <FacebookIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor, }}>
                                    <TwitterIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor, }}>
                                    <InstagramIcon fontSize='small' />
                                </IconButton>
                                <IconButton sx={{ color: primaryColor, }}>
                                    <LinkedInIcon fontSize='small' />
                                </IconButton>

                            </Box>
                        </Drawer>

                    </Box>


                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 3 }}>
                        {pages.map((page) => (
                            <Button component={Link} to={page.title === 'Mutuelle Santé' ? page.to : undefined}
                                key={page.id}
                                endIcon={page.title === 'Assurances' ? <KeyboardArrowDownIcon /> : ''}
                                onClick={page.title === 'Assurances' ? handleOpenAss : handleDrawerToggle && page.title === 'Contact' ? handleClickOpen3 : undefined}
                                sx={{ my: 3, color: 'oPrimaryColor', fontWeight: 'bold', '&:hover': { color: primaryColor } }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <a href='https://www.facebook.com/share/M87YkvwikkZuynCV/?mibextid=LQQJ4d' target='_blank'>
                            <IconButton sx={{ color: primaryColor, }}>
                                <FacebookIcon fontSize='small' />
                            </IconButton>
                        </a>
                        <a target='_blank' style={{ pointerEvents: 'none' }}>
                            <IconButton sx={{ color: primaryColor, }}>
                                <TwitterIcon fontSize='small' />
                            </IconButton>
                        </a>
                        <a href='https://www.instagram.com/loccitaneassurance?igsh=bWl4N3N4OXp0eWJo' target='_blank'>
                            <IconButton sx={{ color: primaryColor, }}>
                                <InstagramIcon fontSize='small' />
                            </IconButton>
                        </a>
                        <a target='_blank' style={{ pointerEvents: 'none' }}>
                            <IconButton sx={{ color: primaryColor, }}>
                                <LinkedInIcon fontSize='small' />
                            </IconButton>
                        </a>

                    </Box>

                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorAss}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorAss)}
                        onClose={handleCloseAss}
                    >
                        {assurances.map((assurance) => (
                            <MenuItem key={assurance} onClick={handleCloseAss}>
                                <Button disabled sx={{ color: 'oPrimaryColor', fontWeight: 'bold', '&:hover': { color: primaryColor } }} >
                                    {assurance}
                                </Button>
                            </MenuItem>
                        ))}
                    </Menu>


                </Toolbar>
            </Container>

            <FormContact open3={open3} handleClose3={handleClose3} />


        </AppBar>
    );
}
export default ResponsiveAppBar;