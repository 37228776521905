import { Box, Typography, Link, } from '@mui/material';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import DescriptionIcon from '@mui/icons-material/Description';
import { oPrimaryColor, oSecondaryColor, primaryColor, secondaryColor } from '../App';
import * as React from 'react';
import FormCallback from "./FormCallback";
import FormDevis from "./FormDevis";



function FixedButtons() {


    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    
    

    return (
        <Box sx={{ position: 'fixed', zIndex: 1000, right: 0, top: '50%', transform: 'translateY(-50%)', display: {xs:'none', md:"flex"}, flexDirection: "column", justifyContent: "center", alignItems:'center',  }}>

            <Box component={Link} onClick={handleClickOpen1} sx={{ textDecoration: 'none', display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center', color: 'white', background: secondaryColor, p:.5, width: '100%', borderTopLeftRadius: 5, '&:hover': {color: 'white', background: oSecondaryColor} }}>
                <PhoneCallbackIcon align="center" fontSize='large'/>
                <Typography fontWeight="bold" variant="subtitle2" color="white">Être rappelé</Typography>
            </Box>

            <Box component={Link} onClick={handleClickOpen2} sx={{ textDecoration: 'none', display: "flex", flexDirection: "column", justifyContent: "center", alignItems:'center', color: 'white', background: primaryColor, p:.5, width: '100%', borderBottomLeftRadius: 5, '&:hover': {color: 'white', background: oPrimaryColor} }}>
                <DescriptionIcon align="center" fontSize='large'/>
                <Typography fontWeight="bold" variant="subtitle2" color="white">Devis</Typography>
            </Box>
            
            <FormCallback open1={open1} handleClose1={handleClose1}/>
            <FormDevis open2={open2} handleClose2={handleClose2}/>
            
        </Box>
    );
}

export default FixedButtons;
