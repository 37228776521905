import './App.css';
import ResponsiveAppBar from './components/AppBar';
import FixedButtons from './components/FixedButtons';
import Footer from './components/Footer';

import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import FloatingButton from './components/FloatingButton';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const primaryColor = "#3981bf"
export const oPrimaryColor = "rgba(57, 129, 191, .7)"
export const secondaryColor = "#7ba736"
export const oSecondaryColor = "rgba(123, 167, 54, .7)"



const titles = {
    "/": "Accueil - L'occitane",
    "/mutuelle-sante": "Mutuelle Santé - L'occitane",
    "/404": "Page Erreur - L'occitane"
  }


function App() {

    const location = useLocation()
    React.useEffect( () => {
        document.title = titles[location.pathname] ?? "Page Erreur - L'occitane"
    }, [location]
    )

    return (
        <div className="App">

            <ResponsiveAppBar />
            <FixedButtons />
            <FloatingButton />

            <ScrollRestoration
                getKey={(location, matches) => {
                    const paths = ["/contact"];
                    return paths.includes(location.pathname)
                        ? // home and notifications restore by pathname
                        location.pathname
                        : // everything else by location like the browser
                        location.key;
                }}
            />
            <Outlet />

            <Footer />

        </div>
    );
}

export default App;
