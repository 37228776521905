import React from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Grid, MenuItem, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, RadioGroup} from '@mui/material';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { oSecondaryColor, secondaryColor } from '../App';
import Api from './Api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const CssTextField1 = styled(TextField)({
    '& label': {
        borderBottomColor: 'red',
        fontWeight: 'bold',
    },
    '& label.Mui-focused': {
        color: '#7ba736',
        fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
        borderColor: '#7ba736',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 0,
            borderColor: '#7ba736',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#7ba736',
        },
    },
    
});


    
class FormCallback extends React.Component{

    
    constructor(props){
        super(props);
        this.state={
            nom:'',
            prenom:'',
            tel:'',
            demande:'',
            jour: '',
            creneau:'',
            alert: false,
            success: false

        }
        this.changeHandler=this.changeHandler.bind(this);
        this.submitForm=this.submitForm.bind(this);
        this.closeAlert=this.closeAlert.bind(this);
    }
    
    changeHandler(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    

    async postData() {
        let callback = this.state;
        await Api.post("/callbacks/", callback)
            .then(response => {
                if (response.status === 201) {
                    this.setState({ alert: true, success: true });
                    this.setState({
                        nom:'',
                        prenom:'',
                        tel:'',
                        demande:'',
                        jour: '',
                        creneau:'',
                    });
                }
            });
    }


    submitForm(e){
        e.preventDefault();
        this.postData();
    }

    closeAlert(){
        this.setState({
            alert: false
        });
    }

    render(){


        return (

            <Dialog maxWidth="md" TransitionComponent={Transition} open={this.props.open1} onClose={this.props.handleClose1} sx={{ borderRadius: 0, }}>

                <Grid container>
                    <Grid item xs={12} md={4} sx={{  background: 'linear-gradient(300deg, #7ba736, #3981bf)', }}>
                        <DialogTitle sx={{ color: 'white', typography: 'h5', fontWeight: 'bold', textTransform:'uppercase', mt:2 }}>Être rappelé</DialogTitle>
                        <PhoneCallbackIcon sx={{ width:1, fontSize: {xs:'120px', sm:'140px', md:'160px', lg:'180px'}, color:'rgba(0,0,0,.6)' }} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{  background: 'linear-gradient(340deg, gray, white, white, white, white)', }}>
                            <DialogContent>
                                <form onSubmit={this.submitForm} method="post">
                                    <DialogContentText sx={{ py:2, fontWeight: 'bold' }}>
                                        Remplissez le formumaire suivant et un de nos conseillers vous contactera dans les plus brefs délais.
                                    </DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <CssTextField1 fullWidth id="callback_nom" label="Nom" name="nom" variant="outlined" required value={this.state.nom} type="text" onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7}>
                                            <CssTextField1 fullWidth id="callback_prenom" label="Prénom" name="prenom" variant="outlined" required value={this.state.prenom} onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <CssTextField1 fullWidth id="callback_tel" label="Téléphone" name="tel" variant="outlined" required type="tel" value={this.state.tel} onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <CssTextField1 fullWidth id="callback_demande" label="Votre demande concerne ?" variant="outlined" name="demande" required select value={this.state.demande} onChange={this.changeHandler}>
                                                <MenuItem value="Mutuelle Santé">Mutuelle Santé</MenuItem>
                                            </CssTextField1>    
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt:2 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" sx={{ '&.Mui-focused': { color: secondaryColor, fontWeight: 'bold' }, fontWeight: 'bold' }}>Quel jour souhaiteriez vous être rappelé ?</FormLabel>
                                                <FormGroup aria-label="position" row>
                                                
                                                    <FormControlLabel
                                                        value="Lundi"
                                                        control={<Checkbox size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Lundi" checked={this.state.jour === "Lundi"} name="jour" onChange={this.changeHandler}/>}
                                                        label="Lundi"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Mardi"
                                                        control={<Checkbox size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Mardi" checked={this.state.jour === "Mardi"} name="jour" onChange={this.changeHandler}/>}
                                                        label="Mardi"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Mercredi"
                                                        control={<Checkbox size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Mercredi" checked={this.state.jour === "Mercredi"} name="jour" onChange={this.changeHandler}/>}
                                                        label="Mercredi"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Jeudi"
                                                        control={<Checkbox size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Jeudi" checked={this.state.jour === "Jeudi"} name="jour" onChange={this.changeHandler}/>}
                                                        label="Jeudi"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Vendredi"
                                                        control={<Checkbox size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Vendredi" checked={this.state.jour === "Vendredi"} name="jour" onChange={this.changeHandler}/>}
                                                        label="Vendredi"
                                                        labelPlacement="end"
                                                    />
                                    
                                                </FormGroup>
                                            </FormControl> 
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" sx={{ '&.Mui-focused': { color: secondaryColor, fontWeight: 'bold' }, fontWeight: 'bold' }}>Quel créneau ?</FormLabel>
                                                <RadioGroup aria-label="position" row>
                                                    <FormControlLabel
                                                        value="Matin"
                                                        control={<Radio size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Matin" checked={this.state.creneau === "Matin"} name="creneau" onChange={this.changeHandler}/>}
                                                        label="Matin"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Après-midi"
                                                        control={<Radio size="small" sx={{ '&.Mui-checked': { color: secondaryColor, }, }} value="Après-Midi" checked={this.state.creneau === "Après-Midi"} name="creneau" onChange={this.changeHandler}/>}
                                                        label="Après-midi"
                                                        labelPlacement="end"
                                                    />
                                                    
                                                </RadioGroup>
                                            </FormControl> 
                                        </Grid>
                                        
                                    </Grid>

                                    <DialogActions>
                                        <Button onClick={this.props.handleClose1} sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: 'transparent', fontWeight: 'bold', borderRadius: '0px', color: 'gray', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: 'transparent' } }}>Annuler</Button>
                                        <Button type="submit" sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: secondaryColor, fontWeight: 'bold', borderRadius: '0px', color: 'white', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: oSecondaryColor } }}>Envoyer<SendIcon fontSize="small" sx={{ ml:1 }} /></Button>
                                    </DialogActions>
                                    
                                </form>
                            </DialogContent>
                            
                        </Box>
                    </Grid>
                </Grid>
                
                
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} onClose={this.closeAlert}>
                        
                        { this.state.success ? 
                            <Alert severity="success" sx={{ width: '100%' }}>
                                Votre demande a été prise en compte!
                            </Alert> 
                            : 
                            <Alert severity="error" sx={{ width: '100%' }}>
                                La demande n'a pas été prise en compte.
                            </Alert> 
                        }
                        
                </Snackbar>

            </Dialog>

            
        );
    }
}

export default FormCallback;