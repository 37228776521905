import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Grid } from '@mui/material';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { oSecondaryColor, secondaryColor } from '../App';
import Api from './Api';
import bgForm from "../assets/images/bgform.jpeg"
import { CardCover } from '@mui/joy';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const CssTextField1 = styled(TextField)({
    '& label': {
        borderBottomColor: 'red',
        fontWeight: 'bold',
    },
    '& label.Mui-focused': {
        color: '#7ba736',
        fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
        borderColor: '#7ba736',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 0,
            borderColor: '#7ba736',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#7ba736',
        },
    },
    
});


    
class FormContact extends React.Component{

    
    constructor(props){
        super(props);
        this.state={
            nom:'',
            prenom:'',
            tel:'',
            email:'',
            demande:'',
            alert: false,
            success: false
        }
        this.changeHandler=this.changeHandler.bind(this);
        this.submitForm=this.submitForm.bind(this);
        this.closeAlert=this.closeAlert.bind(this);
    }



    // Input Change Handler
    changeHandler(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    
    async postData() {
        let contact = this.state;
        await Api.post("/contacts/", contact)
            .then(response => {
                if (response.status === 201) {
                    this.setState({ alert: true, success: true });
                    this.setState({
                        nom:'',
                        prenom:'',
                        tel:'',
                        demande:'',
                        email:'',
                    });
                }
            });
    }


    submitForm(e){
        e.preventDefault();
        this.postData();
    }

   
    closeAlert(){
        this.setState({
            alert: false
        });
    }

    render(){
        return (

            <Dialog maxWidth="md" TransitionComponent={Transition} open={this.props.open3} onClose={this.props.handleClose3} sx={{ borderRadius: 0,  }}>

                <Grid container>
                    <Grid item xs={12} md={4} sx={{  background:'linear-gradient(300deg, #7ba736, #3981bf)', display:'flex', flexDirection:'column', justifyContent: 'center' }} >
                        <ConnectWithoutContactIcon sx={{ width:1, fontSize:'180px', color:'rgba(0,0,0,.6)' }} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ position:'relative', backgroundImage: {xs: `url(${bgForm})`, md: `url(${bgForm})`}, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', }}>
                            <CardCover sx={{ background: 'rgba(255,255,255,.6)',  borderRadius: 0, }} />
                            <DialogContent>
                                <form onSubmit={this.submitForm} method="post">
                                    <DialogContentText sx={{ py:2, typography: 'h5', fontWeight: 'bold', position:'relative' }}>
                                        Formulaire de contact
                                    </DialogContentText>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5} md={5}>
                                            <CssTextField1 fullWidth id="callback_nom" label="Nom" name="nom" variant="outlined" required value={this.state.nom} type="text" onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <CssTextField1 fullWidth id="callback_prenom" label="Prénom" name="prenom" variant="outlined" required value={this.state.prenom} onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={6} md={7}>
                                            <CssTextField1 fullWidth id="standard-basic" label="Email" variant="outlined" required type="email" name="email" value={this.state.email} onChange={this.changeHandler} />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CssTextField1 fullWidth id="callback_tel" label="Téléphone" name="tel" variant="outlined" required type="tel" value={this.state.tel} onChange={this.changeHandler}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CssTextField1 fullWidth multiline rows={4} id="callback_tel" label="Votre demande ?" name="demande" variant="outlined" required value={this.state.demande} onChange={this.changeHandler}/>
                                        </Grid>
                                        
                                    </Grid>

                                    <DialogActions>
                                        <Button onClick={this.props.handleClose3} sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: 'transparent', fontWeight: 'bold', borderRadius: '0px', color: 'gray', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: 'transparent' } }}>Annuler</Button>
                                        <Button type="submit" sx={{ button: { xs: 'small', md: 'medium' }, backgroundColor: secondaryColor, fontWeight: 'bold', borderRadius: '0px', color: 'white', justifyContent: 'center', "&:hover": { color: 'black', backgroundColor: oSecondaryColor } }}>Envoyer<SendIcon fontSize="small" sx={{ ml:1 }} /></Button>
                                    </DialogActions>
                                </form>
                            </DialogContent>
                            
                        </Box>
                    </Grid>
                </Grid>
                
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} onClose={this.closeAlert}>
                        
                        { this.state.success ? 
                            <Alert severity="success" sx={{ width: '100%' }}>
                                Votre demande a été prise en compte!
                            </Alert> 
                            : 
                            <Alert severity="error" sx={{ width: '100%' }}>
                                La demande n'a pas été prise en compte.
                            </Alert> 
                        }
                        
                </Snackbar>
                
            </Dialog>


        );
    }
}

export default FormContact;