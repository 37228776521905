import '../assets/uikit/css/uikit.min.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography, Container, Grid, Button } from '@mui/material';
import { primaryColor } from "../App";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Sante from "../assets/images/sante2.png";
import Mutuelle from "../assets/images/mutuelle.png";
import Avantages from "../assets/images/avantages.png";
import Who from "../assets/images/who.png";
import React from 'react';
import FormDevis from '../components/FormDevis';
import { Helmet } from 'react-helmet-async';

export default function HomePage() {

    const [open2, setOpen2] = React.useState(false);
    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    const isDesktop = true

    return (
        <>

        <Helmet>
            <title>Mutuelle Santé - Occitane Assurances</title>
            <meta name="description" content="L'occitane est un courtier en assurances. Vous aurez la possibilité de choisir l'offre la plus avantageuse pour votre profil au meilleur tarif."></meta>
            <link rel='canonical' href='/mutuelle-sante'/>
        </Helmet>

        <Paper id="health" variant="outlined" sx={{ border: 0, width: '100vw', }}>
            
            
            <Box sx={{ background: 'linear-gradient(to left, #3981bf, #fff)' }}>
                <Card sx={{ boxShadow: 'none', borderRadius: 0 , pb:0 }}>

                    <CardContent sx={{ m: 0 }}>
                        <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>

                            <Grid container spacing={4} sx={{ position: 'relative' }}>

                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} sx={{ height: 1 }}>
                                    <Box sx={{  display: "flex", flexDirection: "column", justifyContent: "center", py: {xs:5, md:10, lg:10} }}>
                                        <Typography align="right" sx={{ display: 'block', typography: { xs: 'h5', md: 'h4' }, color: 'white', textTransform: 'uppercase', fontWeight: {xs: 'bold', md: 'bold'},  }} uk-scrollspy="cls: uk-animation-slide-right-small; delay: 500;">
                                            Mutuelle Santé
                                        </Typography>
                                        <br />
                                        <Typography variant="body2" align="right" fontWeight="bold" sx={{ color: 'white', }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 500;">
                                            L'Occitane vous accompagne et compare pour vous les tarifs et les garanties de nombreuses complémentaires, afin de vous offrir des tarifs intéressants et une protection optimale, adaptée à votre situation.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'right', pt:2 }}>
                                            <Button onClick={handleClickOpen2} size="small" sx={{ backgroundColor: 'transparent', border: '2px solid', borderColor: 'white', fontWeight: 'bold', borderRadius: '0px', color: 'white', justifyContent: 'center', "&:hover": { color: primaryColor, backgroundColor: 'white' } }} uk-scrollspy="cls: uk-animation-slide-top-small; delay: 900;">Demande de devis</Button>
                                            <FormDevis open2={open2} handleClose2={handleClose2} demande="Mutuelle Santé" />
                                         </Box>
                                        
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ height: 1, display: {xs: 'none', md: 'block'} }}>
                                    
                                    <Box sx={{ position: 'absolute', bottom:0, left:0, width:{xs: 1, sm: .4, md: .5} }}>
                                        <Box component="img" src={Sante} alt="Mutuelle Sante" uk-scrollspy="cls: uk-animation-slide-bottom-small; delay: 500;"/>
                                    </Box>
                                </Grid>

                            </Grid>

                        </Container>
                    </CardContent>

                </Card>
            </Box>

            <Box sx={{ py:10 }}>


                <Box sx={{  }}>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                        <Typography variant="h5" align="left" fontWeight="bold" sx={{ color: primaryColor, pb:5 }} uk-scrollspy={isDesktop ? "cls: uk-animation-slide-top-small; delay: 1000;" : undefined}>
                            Qu’est-ce qu’une mutuelle santé ?
                        </Typography>
                    </Container>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "md" }, }}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid xs={12} md={6} item order={{ xs: 1, md: 2 }}>
                                <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0, width:1, position: 'relative' }} >
                                    <Box component="img" className="uk-animation-reverse uk-transform-origin-top-bottom" uk-scrollspy="cls: uk-animation-kenburns;" src={Mutuelle} alt="Mutuelle Santé" />
                                    <CardCover sx={{ background: 'linear-gradient(to right, rgba(255,255,255,1) 5%, rgba(0,0,0,0) 40% )',  borderRadius: 0, }} />
                                </CardCover>
                                
                            </Grid>
                            <Grid xs={12} md={6} item order={{ xs: 2, md: 1 }}>
                                <Box sx={{ borderLeft: '5px solid #3981bf', pl:4, py:2, background: 'linear-gradient(to right, #f5f5ef, #fff 70%)' }}>
                                    <Box align="left" sx={{ typography:'body1', color: 'black', }}>
                                        La <Typography sx={{ display: 'inline', }} fontWeight="bold">mutuelle santé</Typography> est une assurance qui complète la prise en charge de vos frais de santé. Ce type de contrat est géré par différents organismes : <Typography sx={{ display: 'inline', }} fontWeight="bold">les sociétés d’assurance, les mutuelles et les institutions de prévoyance</Typography>. Elles ont pour objectif commun de renforcer votre couverture santé. Leurs garanties complémentaires remboursent une partie, voire la totalité de vos dépenses de santé non couvertes par les garanties de base du régime obligatoire. 
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Container>
                </Box>


                <Box sx={{ py:5, mt:5, }}>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                        <Typography variant="h5" align="left" fontWeight="bold" sx={{ color: primaryColor, pb:5 }}>
                            Quels sont les avantages à avoir une complémentaire santé ? 
                        </Typography>
                    </Container>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "md" } }}>
                        <Typography variant="body1" align="left" sx={{ color: 'black' }}>
                            Quelle que soit votre situation personnelle ou professionnelle, la couverture d’une mutuelle santé vous procure de nombreux avantages : 
                            <br/>
                        </Typography>
                        <Box component="ul" sx={{ ml:4 }}>
                            <li>
                                Un <Typography sx={{ display: 'inline', }} fontWeight="bold">complément de remboursement</Typography> sur les consultations et soins médicaux, les <Typography sx={{ display: 'inline', }} fontWeight="bold">garanties d’un contrat collectif</Typography> étant généralement plus protectrices et moins coûteuses, 
                                la <Typography sx={{ display: 'inline', }} fontWeight="bold">prise en charge</Typography> de soins peu ou pas couverts par l’Assurance maladie, comme les  dépassements d’honoraires, soins dentaires, frais optiques, appareils auditifs, médecine douce, sevrage tabagique…
                            </li>
                            <br />
                            <li>
                                Un <Typography sx={{ display: 'inline', }} fontWeight="bold">accès au tiers payant</Typography>, qui vous évite d’avancer de l’argent pour certains frais de santé, comme les médicaments, l’hospitalisation, les prothèses dentaires et les lunettes de vue, 
                                des services d’assistance, en cas d’hospitalisation notamment (aide-ménagère, garde d’enfants…) et de prévention (conseils pratiques, ateliers santé…)
                            </li>
                        </Box>
                        
                        <Box sx={{ display: 'flex', width:1, justifyContent: 'center' }}>
                            <Box component="img" src={Avantages} alt="Avantages Mutuelle Santé" />
                        </Box>
                        
                    </Container>
                </Box>


                <Box sx={{ py:5, background: 'linear-gradient(to right, #fff, #f5f5ef, #fff, #f5f5ef, #fff)' }}>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                        <Typography variant="h5" align="left" fontWeight="bold" sx={{ color: primaryColor, pb:5 }}>
                            Qui peut bénéficier d'une mutuelle santé ? 
                        </Typography>
                    </Container>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "md" } }}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid xs={12} md={5} item order={{ xs: 1, md: 2 }}>
                                <CardCover className="uk-overflow-hidden" sx={{ boxShadow: 'none', borderRadius: 0, width:1, position: 'relative' }} >
                                    <Box component="img" className="uk-animation-reverse uk-transform-origin-top-bottom" uk-scrollspy="cls: uk-animation-kenburns; repeat: true" src={Who} alt="Complémentaire Santé" />
                                </CardCover>
                                
                            </Grid>
                            <Grid xs={12} md={7} item order={{ xs: 1, md: 2 }}>
                                <Box>
                                    <Box align="left" sx={{ typography: 'body1', color: 'black' }}>
                                        Vous pouvez librement souscrire une mutuelle santé à titre individuel. L’adhésion est facultative. Si vous disposez de faibles ressources, des aides existent pour acquérir une complémentaire santé : <Typography sx={{ display: 'inline', }} fontWeight="bold">la couverture maladie universelle complémentaire (CMUC-C) et l’Aide à la complémentaire santé (ACS)</Typography>.
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <br />
                        <Typography variant="body1" align="left" sx={{ color: 'black' }}>
                            En revanche, si vous êtes salarié, votre employeur a l’obligation de vous proposer une complémentaire santé collective, aussi dite mutuelle d’entreprise, avec une adhésion facultative ou obligatoire. Les garanties d’un contrat collectif peuvent aussi profiter à votre conjoint et à vos enfants, avec une extension d’adhésion. En tant que salarié, vous avez la possibilité de cumuler une assurance santé collective et individuelle pour bénéficier d’une protection optimale.
                        </Typography>
                    </Container>
                </Box>


                <Box sx={{ mt:5 }}>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" } }}>
                        <Typography variant="h5" align="left" fontWeight="bold" sx={{ color: primaryColor, pb:5 }}>
                            Comment la mutuelle prend-elle en charge les frais de santé ? 
                        </Typography>
                    </Container>
                    <Container sx={{ maxWidth: { xs: "xs", sm: "sm", md: "md", lg: "md" } }}>
                        <Typography variant="body1" align="left" sx={{ color: 'black' }}>
                            Après les remboursements de l’Assurance maladie, la prise en charge de vos frais par une complémentaire santé dépend des garanties souscrites et du niveau de couverture choisi.  
                        </Typography>
                        <br />
                        <Typography variant="body1" align="left" sx={{ color: 'black' }}>
                            Les contrats proposent plusieurs formules avec différents niveaux de remboursement, vous permettant d’opter pour la mutuelle santé qui vous convient le mieux et d’ajuster ses garanties à vos besoins. Celles-ci sont souvent exprimées en pourcentage du tarif de base de la Sécurité sociale et parfois sous forme de forfait (en optique, par exemple). 
                        </Typography>
                        <br />
                        <Typography variant="body1" align="left" sx={{ color: 'black' }}>
                            Pour une meilleure lisibilité, les organismes assureurs se sont engagés à clarifier et simplifier leurs tableaux de garanties, avec des exemples de remboursement en euros. Ces mesures se déploient en parallèle de la réforme 100 % santé, qui vous fera bénéficier de soins dentaires, optiques et en audiologie sans reste à charge d’ici à 2021.
                        </Typography>
                    </Container>
                </Box>
                

            </Box>
                
        </Paper>
        
        </>
    );
}